import { Footer } from 'components/main/Footer';
import { Headings } from 'components/main/Headings';
import { HeroHeader } from 'components/main/HeroHeader';
import { HomeButtons } from 'components/main/HomeButtons';
import { InfoDrawer } from 'components/main/InfoDrawer';
import { PizzassistantDrawer } from 'components/main/PizzassistantDrawer';
import { SchedulesModal } from 'components/main/SchedulesModal';
import { TodayInfos } from 'components/main/TodayInfos';

export {
  Footer,
  Headings,
  HeroHeader,
  InfoDrawer,
  HomeButtons,
  PizzassistantDrawer,
  SchedulesModal,
  TodayInfos,
};
